import React, {useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import styled from 'styled-components';
import crypto from 'crypto';
import {message} from 'antd';

function AdminTicketCheck(props) {
  const [actions] = useOutlet('actions');
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    const fetchPassword = async () => {
      try {
        actions.setLoading(true);
        const resp = await actions.fetchTicketCheckPassword(
          '8f32bf90-3d47-42f2-9648-a9fe5c9bbb31',
        );
        setInstance(resp);
      } catch (e) {
        alert(e);
      } finally {
        actions.setLoading(false);
      }
    };
    fetchPassword().then(() => 0);
  }, [actions]);

  const FormSpec = {
    schema: {
      title: '',
      type: 'object',
      required: ['password'],
      properties: {
        password: {type: 'string', title: '驗票密碼', default: ''},
      },
    },
    uiSchema: {
      password: {
        'ui:widget': 'password',
      },
    },
  };

  return (
    <Wrapper>
      <Generic.Form
        key={instance?.updated}
        instance={instance}
        schema={FormSpec.schema}
        uiSchema={FormSpec.uiSchema}
        onSubmit={async (formData) => {
          const {id, password} = formData;
          const hash = crypto.createHash('md5').update(password);
          const encryption = hash.digest('hex');
          if (!!id) {
            const nextInstance = await actions.updateTicketCheckPassword({
              id: id,
              values: {password: encryption},
            });
            setInstance(nextInstance);
            message.success('驗票密碼更新成功');
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminTicketCheck;
